.gallery {
  width: 100%;
  padding-top: 125px;
  padding-bottom: 95px;
  background-color: $light-gray;
  overflow: hidden;
  @media (max-width: 991px) {
    padding-top: 100px;
    padding-bottom: 75px;
  }
  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 65px;
  }

  &__slider {
    width: 100%;
    position: relative;

    .slider-arrow.prev {
      left: calc(50% - 1146px / 2);
      @media (max-width: 1240px) {
        left: calc(50% - 991px / 2);
      }
      @media (max-width: 991px){
        left: 5%;
      }
      @media (max-width: 450px){
        left: 0px;
      }
    }

    .slider-arrow.next {
      right: calc(50% - 1055px / 2);
      @media (max-width: 1240px) {
        right: calc(50% - 890px / 2);
      }
      @media (max-width: 991px) {
        right: 5%;
      }
      @media (max-width: 450px){
        right: 0px;
      }
    }
  }

  &__slides {
    width: 100%;

    .slick-list {
      margin-right: -20px;
    }
  }

  &__slide {
    position: relative;
    margin: 0 20px 65px;
    @media (min-width: 1241px) {
      width: 350px !important;
    }
    @media (min-width: 991px) and (max-width: 1240px) {
      width: 290px !important;
    }
    @media (max-width: 991px) {
      width: 350px !important;
    }
    @media (max-width: 575px){
      width: 290px!important;

    }
    @media (max-width: 450px){
      width: 240px!important;
    }

    &.slick-current.slick-active {
      &:after{
        background-color: #000;
      }
    }

    &:before {
      content: "";
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      z-index: 3;
      position: absolute;
      left: -30px;
      top: 0;
      background-color: rgba(248, 245, 239, 0.7);
      opacity: 1;
      display: block;
      transition: 0.3s;

    }
    &:after{
      content: "";
      width: 100%;
      height: 240px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      background-color: #E6DBC6;
      @media (min-width: 991px) and (max-width: 1240px) {

        height: 201px;
      }
      @media (max-width: 991px) {
        height: 242px;
      }
      @media (max-width: 575px){
        height: 201px;

      }
      @media (max-width: 450px){
        height: 166px;
      }
    }
    &.slick-current,
    &.prevSlide,
    &.nextSlide {
      &:before {
        opacity: 0;
        z-index: -1;
      }
    }

    &.prevSlide,
    &.nextSlide {
      &:before {
        @media (max-width: 991px) {
          opacity: 1;
          z-index: 1;
        }
      }
    }
    .form__item.button .button{
      width: 100%;
      margin-left: -15px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    height: 242px;
    position: relative;
    z-index: 2;
    left: -15px;
    bottom: -15px;
    picture {
      img {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
      }
    }
    @media (min-width: 991px) and (max-width: 1240px) {

      height: 201px;
    }
    @media (max-width: 991px) {
      height: 242px;
    }
    @media (max-width: 575px){
      height: 201px;

    }
    @media (max-width: 450px){
      height: 166px;
    }
  }

  &__price {
    z-index: 2;
    background-color: $light-gray;
    padding-top: 22px;
    width: 225px;
    margin-bottom: 25px;
    margin-left: -15px;
    @media (max-width: 767px){
      margin-bottom: 15px;
    }
    span {
      font-size: 14px;
      line-height: 16px;
      color: #656565;
      margin-bottom: 6px;
      display: block;
      position: relative;
      sup{
        position: absolute;
        top: -3px;
      }
    }

    p {
      font-size: 28px;
      line-height: 33px;
      color: $dark-red;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}
