.collaboration {
	display: block;
	background-image: url(../images/collaboration-bg.png);
	background-position: center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	padding: 130px 0;
	color: #fff;
	@media (max-width: 991px){
		padding: 100px 0;
	}
	@media (max-width: 767px){
		padding: 70px 0;
	}
	&__left{
		width: 50%;
		position: relative;
		&:before{
			content: "";
			width: 443px;
			height: 398px;
			position: absolute;
			left: -30%;
			top: 50px;
			background-image: url(../images/icons/point.svg);
			-webkit-background-size: 100%;
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
			@media (max-width: 1024px){
				opacity: 0.5;
			}
			@media (max-width: 767px){
				content: none;
			}
		}
		@media (max-width: 767px){
			width: 100%;
			margin-bottom: 30px;
		}
		.page-title{
			text-align: left;
			color: #fff;
			@media (max-width: 1199px){
				font-size: 38px;
				line-height: 47px;
			}
			@media (max-width: 991px){
				font-size: 28px;
				line-height: 37px;
			}
			@media (max-width: 767px){
				margin-bottom: 30px;
			}
		}
		p:not(.page-title){
			font-size: 18px;
			line-height: 28px;
			font-weight: 500;
			width: 69%;
			@media (max-width: 767px){
				width: 100%;

			}
		}
	}
	&__right{
		width: 50%;
		padding-left: 69px;
		@media (max-width: 1199px){
			padding-left: 39px;
		}
		@media (max-width: 767px){
			width: 100%;
			padding-left: 0;
		}

	}
	&__advantage{
		position: relative;
		padding-left: 57px;
		margin-bottom: 23px;
		width: 80%;

		&:before{
			content: "";
			width: 24px;
			height: 24px;
			position: absolute;
			left: 0;
			top: 7px;
			background-image: url(../images/icons/uncheck.svg);
			-webkit-background-size: 100%;
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
		}
		.check-icon{
			position: absolute;
			width: 24px;
			height: 24px;
			left: 0;
			top: 7px;

			.check1{
				content: "";
				position: absolute;
				width: 10px;
				height: 2px;
				top: 11px;
				left: 3px;
				border-radius: 10px;
				transform: rotate(45deg);
				background-color: #fff;
				opacity: 0;
				transition: 0.5s;
				transition-delay: 1s;
			}
			.check2{
				content: "";
				position: absolute;
				width: 18px;
				height: 2px;
				top: 8.5px;
				left: 8px;
				border-radius: 10px;
				transform: rotate(-45deg);
				background-color: #fff;
				opacity: 0;
				transition: 0.5s;
				transition-delay: 1.3s;
			}
			&.animated{
				.check1,
				.check2{
					opacity: 1;
				}
			}
		}
		@media (max-width: 1199px){
			width: 90%;
		}
		@media (max-width: 991px){
			width: 100%;
		}
	}
	&__title{
		font-size: 14px;
		line-height: 30px;
		text-transform: uppercase;
	}
	&__desc{
		font-size: 14px;
		line-height: 24px;
	}
}
