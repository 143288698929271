.certifications {
  display: block;
  background-image: url(../images/calc-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 105px 0 140px;
  @media (max-width: 991px) {
    padding: 80px 0 110px;
  }
  @media (max-width: 767px) {
    padding: 70px 0 100px;
  }

  .container {
    position: relative;
  }

  .page-title {
    color: #fff;
    margin-bottom: 80px;
    width: 100%;
    @media (max-width: 991px) {
      margin-bottom: 60px;
    }
    @media (max-width: 767px) {
      margin-bottom: 35px;
    }
  }

  &__slider {
    width: 100%;

    .slick-list {
      margin-left: -10px;

      @media (min-width: 767px) and (max-width: 1124px) {
        margin-left: 0px;
      }
      @media (max-width: 767px) {
        margin-left: 0px;
      }
    }
  }

  &__slide {
    margin-right: 30px;
    margin-left: 30px;
    @media (max-width: 1124px) {
      margin-left: 20px;
      margin-right: 20px;
    }
    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 0px;
    }

  }

  &__img {
    position: relative;
    padding-top: 30px;
    max-width: 451px;
    @media (max-width: 767px){
      margin: 0 auto;

    }
    @media (max-width: 600px){
      max-width: 80%;
      padding-top: 20px;
    }
    img {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      right: -30px;
      top: -30px;
      background-color: #454343;
      @media (min-width: 767px) and (max-width: 1124px) {
        right: -20px;
        top: -20px;
      }
      @media (max-width: 600px){
        top: -20px;
        right: -20px;
      }

    }
  }

  .slider-arrow {
    position: absolute;
    top: calc(50% + 16px);

    &.prev {
      left: -40px;
      @media (max-width: 650px) {
        left: 0px;
      }
    }

    &.next {
      right: 0;
      @media (max-width: 1240px) {
        right: -60px;
      }
      @media (max-width: 1124px) {
        right: -40px;
      }
      @media (max-width: 991px){
        right: -32px;
      }
      @media (max-width: 650px){
        right: 0px;
      }
    }
  }
}
