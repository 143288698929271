.main-contacts {
  display: block;
  background-image: url(../images/main-contacts-bg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  -webkit-background-size: cover;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1124px){
    padding-bottom: 80px;
  }
  @media (max-width: 767px){
    padding-top: 70px;
  }
  &__block {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 70px 70px 70px 100px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    @media (max-width: 767px){
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 50px;
      padding-bottom: 40px;
    }
    @media (max-width: 575px){
      padding-right: 25px;
      padding-left: 25px;
    }
  }
  &__caption{
    margin-bottom: 28px;
    width: 100%;
    font-weight: 500;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  &__title {
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    color: $dark-green;
    margin-bottom: 7px;
    font-weight: 600;
    position: relative;
    z-index: 1;
  }
  &__item {
    display: flex;
    flex-direction: column;
    @media (max-width: 1124px){
      width: 48%;
      margin-bottom: 20px;
    }
    @media (max-width: 500px){
      width: 100%;
    }

    a, p {
      color: #fff;
      color: #333;
      font-size: 14px;
      line-height: 24px;
      position: relative;
      &:before {
        content: "";
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 100%;
        background-size: 100%;
        position: absolute;
      }

      &.phone {
        padding-left: 17px;
        &:before {
          width: 10px;
          height: 10px;
          margin-top: 6px;
          background-image: url(../images/icons/phone.svg);
        }
      }

      &.email {
        padding-left: 29px;
        &:before {
          width: 14px;
          height: 10px;
          margin-top: 7px;
          background-image: url(../images/icons/email.svg);
        }
      }

      &.address {
        max-width: 185px;
        padding-left: 26px;
        &:before {
          width: 11px;
          height: 15px;
          margin-top: 2px;
          background-image: url(../images/icons/placemark.svg);
        }
      }
    }
  }
}
