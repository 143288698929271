.type-complex {
  display: block;
  padding: 137px 0 206px;
  background-image: url(../images/type-complex-bg.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  @media (max-width: 991px) {
    padding-top: 100px;
    padding-bottom: 166px;
  }
  @media (max-width: 767px) {
    padding-top: 70px;
    padding-bottom: 96px;
  }

  &__item {
    width: 362px;
    width: 31.58%;
    margin-right: 30px;
    height: 538px;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

    &.onScroll {
      .type-complex__title {
        background-color: $light-green;
      }
    }

    @media (min-width: 991px) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @media (max-width: 1240px) {
      margin-right: 26px;
    }
    @media (max-width: 1124px) {
      width: 31%;
    }
    @media (max-width: 991px) {
      margin-right: auto;
      margin-left: auto;
      width: calc(50% - 13px);
      margin-bottom: 30px;
      &:first-child {
        margin-right: 26px;
      }
    }
    @media (max-width: 767px) {
      width: calc(50% - 7.5px);
      &:first-child {
        margin-right: 15px;
      }
    }
    @media (max-width: 575px) {
      width: 100%;
      &:first-child {
        margin-right: 0;
      }
    }
  }

  &__title {
    background-color: $green;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: 0.3s;
  }

  &__list {
    height: calc(100% - 90px);
  }

  &__inner {
    padding: 30px;
    background-color: #fff;

    &:nth-child(2n) {
      background-color: #FAFAFA;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      color: #333;
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }
  }

}
