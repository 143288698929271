.our-partners {
  display: block;
  background-image: url(../images/our-partners-bg.png);
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 126px 0 200px;
  @media (max-width: 991px){
    padding-top: 106px;
    padding-bottom: 150px;
  }
  @media (max-width: 767px){
    padding-top: 70px;
    padding-bottom: 80px;
  }
  .page-title{
    color: #fff;
  }
  &__slider {
    width: 100%;
    position: relative;
    .slider-arrow{
      &.prev{
        left: 30px;
        @media (max-width: 1124px){
          left: -15px;
        }
      }
      &.next{
        right: 30px;
        @media (max-width: 1124px){
          right: -15px;
        }
      }
    }
    .slick-dots{
      bottom: -50px;
    }

  }

  &__slides {
    width: 100%;

    .slick-list {
      padding-left: 30px;
      margin-left: -29px;
      margin-right: -29px;
    }
  }

  &__slide {
    //box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    background: #3E3E3E;
    background: #fff;
    padding: 55px 25px 55px 35px;
    margin-right: 30px;
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    @media (max-width: 767px){
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  &__img{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px){
      height: 45px;
    }
    img{

      max-height: 100%;
    }
  }
  &__about {
    color: #fff;
    color: #000;
    text-transform: uppercase;
    width: calc(100% - 85px);
    margin-left: 0px;
    @media (max-width: 991px){
      width: calc(100% - 80px);
      margin-left: 20px;
    }
    @media (max-width: 767px){
      width: calc(100% - 60px);
      margin-left: 15px;
    }
    p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      @media (max-width: 991px){
        font-size: 18px;
        line-height: 28px;
      }
      @media (max-width: 767px){
        font-size: 16px;
        line-height: 26px;
      }
    }

    span {
      line-height: 30px;
      font-size: 14px;
      display: block;
      font-weight: 600;
    }
  }


}
