.main-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 120px;
  @media (max-width: 991px) {
    margin-bottom: 90px;
  }
  @media (max-width: 767px) {
    margin-bottom: 70px;
  }

  &__logo {
    position: absolute;
    top: 100px;
    width: 280px;
    z-index: 1;

    @media (max-width: 900px) {
      width: 167px;
      top: 67px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        left: calc(50% - 133.5px);
      }
    }
    @media (min-width: 900px) and (max-height: 665px) {
      width: 200px;
      top: 60px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        left: calc(50% - 100px);
      }
    }
    @media (max-width: 900px) and (orientation: landscape) {
      top: 20px;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
     left: calc(50% - 140px);
    }
  }

  &__slides {
    width: 100%;
    margin-bottom: 0 !important;
  }

  &__img {
    z-index: 1;
    position: absolute;
    width: 100%;
    min-height: 500px;
    height: 100vh;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    @media (max-width: 991px) and (orientation: landscape) {
      min-height: 320px;
    }
  }

  &__slide {
    width: 100%;
    height: 100vh;
    min-height: 500px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    @media (max-width: 991px) and (orientation: landscape) {
      min-height: 320px;
    }


    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #090909 0%, rgba(9, 9, 9, 0.317708) 28.84%, rgba(9, 9, 9, 0) 221.5%);
      opacity: 0.2;
    }
  }

  &__text {
    position: absolute;
    z-index: 3;
    width: $xl;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      left: calc(50% - 573px);
    }
    @media (max-width: 1240px) {
      width: 991px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        left: calc(50% - 495.5px);
      }
    }
    @media (max-width: 1124px) {
      width: 900px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        left: calc(50% - 450px);
      }
    }
    @media (max-width: 767px) {
      width: auto;
    }
  }

  &__title {
    font-size: 60px;
    line-height: 73px;
    color: #fff;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 70%;
    margin-bottom: 20px;
    font-weight: 600;
    @media (max-width: 1124px) {
      font-size: 45px;
      line-height: 67px;
      width: 70%;
    }
    @media (max-width: 767px) {
      font-size: 35px;
      line-height: 47px;
    }
    @media (max-width: 575px) {
      font-size: 25px;
      line-height: 37px;
      width: 73%;
    }
    @media (max-width: 991px) and (orientation: landscape) {
      font-size: 35px;
      line-height: 47px;
    }
    @media (max-height: 375px) and (orientation: landscape){
      font-size: 28px;
      line-height: 38px;
    }
  }

  &__desc {
    font-size: 32px;
    line-height: 38px;
    color: #fff;
    text-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    width: 40%;
    @media (max-width: 1124px) {
      width: 65%;
      font-size: 28px;
      line-height: 34px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
    }
    @media (max-width: 575px) {
      font-size: 18px;
      line-height: 24px;
      width: 73%;

    }
    @media (max-width: 991px) and (orientation: landscape) {
      font-size: 22px;
      line-height: 28px;
    }
    @media (max-height: 375px) and (orientation: landscape){
      font-size: 18px;
      line-height: 24px;
    }
  }
}
