.advantages {
  display: flex;
  flex-direction: column;
  margin-bottom: 115px;
  overflow: hidden;
  @media (max-width: 991px){
    margin-bottom: 85px;
  }
  @media (max-width: 767px){
    margin-bottom: 70px;
  }
  &__bg {
    margin-bottom: 80px !important;
    padding-top: 70px;
    padding-bottom: 80px;
    position: relative;
    @media (max-width: 991px){
      padding-top: 50px;
      padding-bottom: 60px;
      margin-bottom: 40px!important;
    }
    &:before {
      content: "";
      position: absolute;
      left: -40px;
      width: 100vw;
      top: 0;
      background-image: url(../images/advantages-bg.png);
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      @media (max-width: 1240px) {
        width: calc(100vw + 40px);
      }

    }

  }

  .container {
    justify-content: space-between;
  }

  &__item {
    width: 27.22%;
    position: relative;
    z-index: 1;
    @media (max-width: 991px) {
      width: 48%;
      margin: 0 auto 20px;
      &:last-child {
        margin: 0 auto;
      }
    }
    @media (max-width: 575px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__icon {
    height: 43px;
    margin-bottom: 5px;
    overflow: hidden;
    img{
      //transition: 0.45s;
      //transform: translateY(30px);
      height: 100%;
    }
    &.animated{
      img{
        //transform: translateY(0px);
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin-bottom: 8px;
    line-height: 30px;

    &:before {
      content: "";
      left: 0;
      bottom: -1px;
      height: 3px;
      width: 55px;
      background-color: $dark-green;
      position: absolute;
    }
  }

  &__desc {
    font-size: 14px;
    line-height: 24px;
    color: #333;
  }
}
