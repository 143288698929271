.footer {
	display: block;
	background-image: url("../images/calc-bg.png");

	padding: 25px 0;
	@media (max-width: 991px){
		padding: 40px 0;
	}
	.container{
		justify-content: space-between;
		@media (max-width: 991px){
			justify-content: center;
		}
	}
	&__logo{
		width: 148px;
		height: 43px;
		display: block;
		background-image: url("../images/logo2.png");
		-webkit-background-size: contain;
		background-size: contain;
		background-repeat: no-repeat;
		@media (max-width: 991px){
			margin-bottom: 10px;
		}
	}

}
.copyright{
	display: flex;
	color:  rgba(255, 255, 255, 0.6);
	font-size: 14px;
	line-height: 24px;
	display: flex;
	align-items: center;
	@media (max-width: 991px){
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}
	p{
		margin-right: 62px;
		@media (max-width: 991px){
			margin-right: 0;
		}
	}
	a{
		display: flex;
		align-items: center;
		color:  rgba(255, 255, 255, 0.6);
		img{
			margin-left: 20px;
		}
	}
}
.up{
	width: 54px;
	height: 54px;
	position: fixed;
	right: 30px;
	bottom: 30px;
	cursor: pointer;
	background-image: url("../images/icons/up.svg");
	background-repeat: no-repeat;
	-webkit-background-size: 50%;
	background-size: 50%;
	background-position: center;
	background-color: rgba(0,0,0, 0.7);
	cursor: pointer;
	border-radius: 4px;
	opacity: 0;
	transition: opacity 0.3s;
	&.show{
		opacity: 1;
	}
	@media (max-width: 991px){
		width: 44px;
		height: 44px;
		right: 20px;
		bottom: 20px;
	}
	@media (max-width: 767px){
		width: 32px;
		height: 32px;
		right: 15px;
		bottom: 15px;
	}
}
