.about-us {
	display: block;
	margin-bottom: 65px;
	&__img{
		width: calc(100% - 515px);
		margin-top: 20px;
		@media (max-width: 1240px){
			width: calc(50% - 55px);
		}
		@media (max-width: 767px){
			width: auto;
			max-width: 90%;
			margin: 0 auto;
		}
		picture{
			max-width: 100%;
		}
	}
	&__text{
		width: 460px;
		margin-left: 55px;
		@media (max-width: 1240px){
			width: 50%;
		}
		@media (max-width: 767px){
			width: 100%;
			margin-left: 0;
		}

	}
	&__title{
		font-size: 36px;
		line-height: 42px;
		margin-bottom: 36px;
		font-weight: 600;
		span{
			color: $green;
		}
		@media (max-width: 991px){
			font-size: 24px;
			line-height: 32px;
			margin-bottom: 24px;
		}
	}
	&__desc{
		font-size: 14px;
		line-height: 24px;
		p{
			font-weight: 500;
			margin-bottom: 30px;
			@media (max-width: 991px){
				margin-bottom: 20px;
			}
			b{
				font-weight: 600;
			}
		}
		span{
			font-weight: 400;
		}
	}
}
