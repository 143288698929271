/* This stylesheet generated by Transfonter (https://transfonter.org)  */
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url("../fonts/HelveticaNeue.eot");
  src: local("HelveticaNeue"), url("../fonts/HelveticaNeue.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue.woff") format("woff"), url("../fonts/HelveticaNeue.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url("../fonts/HelveticaNeue-Bold.eot");
  src: local("HelveticaNeue-Bold"), url("../fonts/HelveticaNeue-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-Bold.woff") format("woff"), url("../fonts/HelveticaNeue-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url("../fonts/HelveticaNeue-CondensedBlack.eot");
  src: local("HelveticaNeue-CondensedBlack"), url("../fonts/HelveticaNeue-CondensedBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-CondensedBlack.woff") format("woff"), url("../fonts/HelveticaNeue-CondensedBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url("../fonts/HelveticaNeue-Light.eot");
  src: local("HelveticaNeue-Light"), url("../fonts/HelveticaNeue-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-Light.woff") format("woff"), url("../fonts/HelveticaNeue-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url("../fonts/HelveticaNeue-UltraLight.eot");
  src: local("HelveticaNeue-UltraLight"), url("../fonts/HelveticaNeue-UltraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeue-UltraLight.woff") format("woff"), url("../fonts/HelveticaNeue-UltraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  cursor: default; }

body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

a {
  background: transparent; }

*:focus,
*:active,
*:hover {
  outline: none; }

hr {
  box-sizing: content-box;
  height: 0; }

ol,
ul {
  list-style: none; }

pre {
  tab-size: 4;
  white-space: pre-wrap; }

q {
  quotes: '\201C', '\201D', '\2018', '\2019'; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button {
  overflow: visible; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

button,
input,
select[multiple],
textarea {
  background-image: none; }

input,
select,
textarea {
  border-radius: 0;
  box-shadow: none; }

input,
textarea {
  resize: none;
  user-select: text; }

[placeholder]:focus::placeholder {
  color: transparent; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.about-us {
  display: block;
  margin-bottom: 65px; }
  .about-us__img {
    width: calc(100% - 515px);
    margin-top: 20px; }
    @media (max-width: 1240px) {
      .about-us__img {
        width: calc(50% - 55px); } }
    @media (max-width: 767px) {
      .about-us__img {
        width: auto;
        max-width: 90%;
        margin: 0 auto; } }
    .about-us__img picture {
      max-width: 100%; }
  .about-us__text {
    width: 460px;
    margin-left: 55px; }
    @media (max-width: 1240px) {
      .about-us__text {
        width: 50%; } }
    @media (max-width: 767px) {
      .about-us__text {
        width: 100%;
        margin-left: 0; } }
  .about-us__title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 36px;
    font-weight: 600; }
    .about-us__title span {
      color: #89a549; }
    @media (max-width: 991px) {
      .about-us__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px; } }
  .about-us__desc {
    font-size: 14px;
    line-height: 24px; }
    .about-us__desc p {
      font-weight: 500;
      margin-bottom: 30px; }
      @media (max-width: 991px) {
        .about-us__desc p {
          margin-bottom: 20px; } }
      .about-us__desc p b {
        font-weight: 600; }
    .about-us__desc span {
      font-weight: 400; }

.advantages {
  display: flex;
  flex-direction: column;
  margin-bottom: 115px;
  overflow: hidden; }
  @media (max-width: 991px) {
    .advantages {
      margin-bottom: 85px; } }
  @media (max-width: 767px) {
    .advantages {
      margin-bottom: 70px; } }
  .advantages__bg {
    margin-bottom: 80px !important;
    padding-top: 70px;
    padding-bottom: 80px;
    position: relative; }
    @media (max-width: 991px) {
      .advantages__bg {
        padding-top: 50px;
        padding-bottom: 60px;
        margin-bottom: 40px !important; } }
    .advantages__bg:before {
      content: "";
      position: absolute;
      left: -40px;
      width: 100vw;
      top: 0;
      background-image: url(../images/advantages-bg.png);
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%; }
      @media (max-width: 1240px) {
        .advantages__bg:before {
          width: calc(100vw + 40px); } }
  .advantages .container {
    justify-content: space-between; }
  .advantages__item {
    width: 27.22%;
    position: relative;
    z-index: 1; }
    @media (max-width: 991px) {
      .advantages__item {
        width: 48%;
        margin: 0 auto 20px; }
        .advantages__item:last-child {
          margin: 0 auto; } }
    @media (max-width: 575px) {
      .advantages__item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; } }
  .advantages__icon {
    height: 43px;
    margin-bottom: 5px;
    overflow: hidden; }
    .advantages__icon img {
      height: 100%; }
  .advantages__title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin-bottom: 8px;
    line-height: 30px; }
    .advantages__title:before {
      content: "";
      left: 0;
      bottom: -1px;
      height: 3px;
      width: 55px;
      background-color: #7a9341;
      position: absolute; }
  .advantages__desc {
    font-size: 14px;
    line-height: 24px;
    color: #333; }

.calculate {
  display: block;
  background-image: url(../images/calc-bg.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 127px;
  padding-bottom: 140px; }
  @media (max-width: 991px) {
    .calculate {
      padding-top: 100px;
      padding-bottom: 120px; } }
  @media (max-width: 767px) {
    .calculate {
      padding-top: 70px;
      padding-bottom: 90px; } }
  .calculate__title {
    font-size: 36px;
    line-height: 43px;
    color: #fff;
    width: 450px;
    max-width: 100%;
    text-align: center;
    margin: 0 auto 60px;
    font-weight: 600; }
  .calculate .main-contacts__block {
    margin-bottom: 94px;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    margin-top: 0; }
    .calculate .main-contacts__block a, .calculate .main-contacts__block p {
      color: #fff; }
  .calculate__form {
    width: 100%; }

.certifications {
  display: block;
  background-image: url(../images/calc-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 105px 0 140px; }
  @media (max-width: 991px) {
    .certifications {
      padding: 80px 0 110px; } }
  @media (max-width: 767px) {
    .certifications {
      padding: 70px 0 100px; } }
  .certifications .container {
    position: relative; }
  .certifications .page-title {
    color: #fff;
    margin-bottom: 80px;
    width: 100%; }
    @media (max-width: 991px) {
      .certifications .page-title {
        margin-bottom: 60px; } }
    @media (max-width: 767px) {
      .certifications .page-title {
        margin-bottom: 35px; } }
  .certifications__slider {
    width: 100%; }
    .certifications__slider .slick-list {
      margin-left: -10px; }
      @media (min-width: 767px) and (max-width: 1124px) {
        .certifications__slider .slick-list {
          margin-left: 0px; } }
      @media (max-width: 767px) {
        .certifications__slider .slick-list {
          margin-left: 0px; } }
  .certifications__slide {
    margin-right: 30px;
    margin-left: 30px; }
    @media (max-width: 1124px) {
      .certifications__slide {
        margin-left: 20px;
        margin-right: 20px; } }
    @media (max-width: 767px) {
      .certifications__slide {
        margin-left: 0;
        margin-right: 0px; } }
  .certifications__img {
    position: relative;
    padding-top: 30px;
    max-width: 451px; }
    @media (max-width: 767px) {
      .certifications__img {
        margin: 0 auto; } }
    @media (max-width: 600px) {
      .certifications__img {
        max-width: 80%;
        padding-top: 20px; } }
    .certifications__img img {
      position: relative;
      z-index: 2; }
    .certifications__img:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      right: -30px;
      top: -30px;
      background-color: #454343; }
      @media (min-width: 767px) and (max-width: 1124px) {
        .certifications__img:before {
          right: -20px;
          top: -20px; } }
      @media (max-width: 600px) {
        .certifications__img:before {
          top: -20px;
          right: -20px; } }
  .certifications .slider-arrow {
    position: absolute;
    top: calc(50% + 16px); }
    .certifications .slider-arrow.prev {
      left: -40px; }
      @media (max-width: 650px) {
        .certifications .slider-arrow.prev {
          left: 0px; } }
    .certifications .slider-arrow.next {
      right: 0; }
      @media (max-width: 1240px) {
        .certifications .slider-arrow.next {
          right: -60px; } }
      @media (max-width: 1124px) {
        .certifications .slider-arrow.next {
          right: -40px; } }
      @media (max-width: 991px) {
        .certifications .slider-arrow.next {
          right: -32px; } }
      @media (max-width: 650px) {
        .certifications .slider-arrow.next {
          right: 0px; } }

.collaboration {
  display: block;
  background-image: url(../images/collaboration-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 130px 0;
  color: #fff; }
  @media (max-width: 991px) {
    .collaboration {
      padding: 100px 0; } }
  @media (max-width: 767px) {
    .collaboration {
      padding: 70px 0; } }
  .collaboration__left {
    width: 50%;
    position: relative; }
    .collaboration__left:before {
      content: "";
      width: 443px;
      height: 398px;
      position: absolute;
      left: -30%;
      top: 50px;
      background-image: url(../images/icons/point.svg);
      -webkit-background-size: 100%;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat; }
      @media (max-width: 1024px) {
        .collaboration__left:before {
          opacity: 0.5; } }
      @media (max-width: 767px) {
        .collaboration__left:before {
          content: none; } }
    @media (max-width: 767px) {
      .collaboration__left {
        width: 100%;
        margin-bottom: 30px; } }
    .collaboration__left .page-title {
      text-align: left;
      color: #fff; }
      @media (max-width: 1199px) {
        .collaboration__left .page-title {
          font-size: 38px;
          line-height: 47px; } }
      @media (max-width: 991px) {
        .collaboration__left .page-title {
          font-size: 28px;
          line-height: 37px; } }
      @media (max-width: 767px) {
        .collaboration__left .page-title {
          margin-bottom: 30px; } }
    .collaboration__left p:not(.page-title) {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      width: 69%; }
      @media (max-width: 767px) {
        .collaboration__left p:not(.page-title) {
          width: 100%; } }
  .collaboration__right {
    width: 50%;
    padding-left: 69px; }
    @media (max-width: 1199px) {
      .collaboration__right {
        padding-left: 39px; } }
    @media (max-width: 767px) {
      .collaboration__right {
        width: 100%;
        padding-left: 0; } }
  .collaboration__advantage {
    position: relative;
    padding-left: 57px;
    margin-bottom: 23px;
    width: 80%; }
    .collaboration__advantage:before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 7px;
      background-image: url(../images/icons/uncheck.svg);
      -webkit-background-size: 100%;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat; }
    .collaboration__advantage .check-icon {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 0;
      top: 7px; }
      .collaboration__advantage .check-icon .check1 {
        content: "";
        position: absolute;
        width: 10px;
        height: 2px;
        top: 11px;
        left: 3px;
        border-radius: 10px;
        transform: rotate(45deg);
        background-color: #fff;
        opacity: 0;
        transition: 0.5s;
        transition-delay: 1s; }
      .collaboration__advantage .check-icon .check2 {
        content: "";
        position: absolute;
        width: 18px;
        height: 2px;
        top: 8.5px;
        left: 8px;
        border-radius: 10px;
        transform: rotate(-45deg);
        background-color: #fff;
        opacity: 0;
        transition: 0.5s;
        transition-delay: 1.3s; }
      .collaboration__advantage .check-icon.animated .check1,
      .collaboration__advantage .check-icon.animated .check2 {
        opacity: 1; }
    @media (max-width: 1199px) {
      .collaboration__advantage {
        width: 90%; } }
    @media (max-width: 991px) {
      .collaboration__advantage {
        width: 100%; } }
  .collaboration__title {
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase; }
  .collaboration__desc {
    font-size: 14px;
    line-height: 24px; }

.dialogs {
  display: block; }

.more-dialog {
  background-color: black;
  width: 700px;
  max-width: 100%;
  padding: 50px;
  display: none; }

.fancybox-is-open .fancybox-bg {
  opacity: 0.2; }

.footer {
  display: block;
  background-image: url("../images/calc-bg.png");
  padding: 25px 0; }
  @media (max-width: 991px) {
    .footer {
      padding: 40px 0; } }
  .footer .container {
    justify-content: space-between; }
    @media (max-width: 991px) {
      .footer .container {
        justify-content: center; } }
  .footer__logo {
    width: 148px;
    height: 43px;
    display: block;
    background-image: url("../images/logo2.png");
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat; }
    @media (max-width: 991px) {
      .footer__logo {
        margin-bottom: 10px; } }

.copyright {
  display: flex;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center; }
  @media (max-width: 991px) {
    .copyright {
      flex-direction: column;
      justify-content: center;
      width: 100%; } }
  .copyright p {
    margin-right: 62px; }
    @media (max-width: 991px) {
      .copyright p {
        margin-right: 0; } }
  .copyright a {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.6); }
    .copyright a img {
      margin-left: 20px; }

.up {
  width: 54px;
  height: 54px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  background-image: url("../images/icons/up.svg");
  background-repeat: no-repeat;
  -webkit-background-size: 50%;
  background-size: 50%;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s; }
  .up.show {
    opacity: 1; }
  @media (max-width: 991px) {
    .up {
      width: 44px;
      height: 44px;
      right: 20px;
      bottom: 20px; } }
  @media (max-width: 767px) {
    .up {
      width: 32px;
      height: 32px;
      right: 15px;
      bottom: 15px; } }

.frame-buildings {
  display: block;
  background-image: url(../images/icons/buildings.svg);
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 130px 0 110px;
  align-items: center;
  justify-content: center; }
  @media (max-width: 991px) {
    .frame-buildings {
      padding: 100px 0 90px; } }
  @media (max-width: 767px) {
    .frame-buildings {
      padding: 70px 0 60px; } }
  .frame-buildings .page-title {
    width: 100%; }
  .frame-buildings__items {
    display: flex;
    flex-wrap: wrap; }
  .frame-buildings__icon {
    height: 129px; }
    .frame-buildings__icon img {
      height: 100%; }
      @media (max-width: 767px) {
        .frame-buildings__icon img {
          margin: 0 auto;
          display: block; } }
  .frame-buildings__item {
    width: 362px;
    width: 31.58%;
    margin-right: 30px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    margin-bottom: 30px;
    padding: 28px; }
    @media (min-width: 991px) {
      .frame-buildings__item:nth-child(3n) {
        margin-right: 0; } }
    @media (max-width: 1240px) {
      .frame-buildings__item {
        width: 31%; } }
    @media (max-width: 991px) {
      .frame-buildings__item {
        width: calc(50% - 15px);
        margin-right: 30px; }
        .frame-buildings__item:nth-child(2n) {
          margin-right: 0; } }
    @media (max-width: 767px) {
      .frame-buildings__item {
        width: calc(50% - 10px);
        padding: 20px;
        margin-right: 20px; }
        .frame-buildings__item:nth-child(2n) {
          margin-right: 0; } }
    @media (max-width: 575px) {
      .frame-buildings__item {
        width: 100%;
        margin-right: 0; } }
  .frame-buildings__title {
    font-size: 14px;
    line-height: 22px;
    color: #7a9341;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 600; }
    @media (max-width: 575px) {
      .frame-buildings__title {
        text-align: center; } }
  .frame-buildings__desc {
    font-size: 14px;
    line-height: 24px;
    color: #333; }

.gallery {
  width: 100%;
  padding-top: 125px;
  padding-bottom: 95px;
  background-color: #f8f5ef;
  overflow: hidden; }
  @media (max-width: 991px) {
    .gallery {
      padding-top: 100px;
      padding-bottom: 75px; } }
  @media (max-width: 767px) {
    .gallery {
      padding-top: 70px;
      padding-bottom: 65px; } }
  .gallery__slider {
    width: 100%;
    position: relative; }
    .gallery__slider .slider-arrow.prev {
      left: calc(50% - 1146px / 2); }
      @media (max-width: 1240px) {
        .gallery__slider .slider-arrow.prev {
          left: calc(50% - 991px / 2); } }
      @media (max-width: 991px) {
        .gallery__slider .slider-arrow.prev {
          left: 5%; } }
      @media (max-width: 450px) {
        .gallery__slider .slider-arrow.prev {
          left: 0px; } }
    .gallery__slider .slider-arrow.next {
      right: calc(50% - 1055px / 2); }
      @media (max-width: 1240px) {
        .gallery__slider .slider-arrow.next {
          right: calc(50% - 890px / 2); } }
      @media (max-width: 991px) {
        .gallery__slider .slider-arrow.next {
          right: 5%; } }
      @media (max-width: 450px) {
        .gallery__slider .slider-arrow.next {
          right: 0px; } }
  .gallery__slides {
    width: 100%; }
    .gallery__slides .slick-list {
      margin-right: -20px; }
  .gallery__slide {
    position: relative;
    margin: 0 20px 65px; }
    @media (min-width: 1241px) {
      .gallery__slide {
        width: 350px !important; } }
    @media (min-width: 991px) and (max-width: 1240px) {
      .gallery__slide {
        width: 290px !important; } }
    @media (max-width: 991px) {
      .gallery__slide {
        width: 350px !important; } }
    @media (max-width: 575px) {
      .gallery__slide {
        width: 290px !important; } }
    @media (max-width: 450px) {
      .gallery__slide {
        width: 240px !important; } }
    .gallery__slide.slick-current.slick-active:after {
      background-color: #000; }
    .gallery__slide:before {
      content: "";
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      z-index: 3;
      position: absolute;
      left: -30px;
      top: 0;
      background-color: rgba(248, 245, 239, 0.7);
      opacity: 1;
      display: block;
      transition: 0.3s; }
    .gallery__slide:after {
      content: "";
      width: 100%;
      height: 240px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      background-color: #E6DBC6; }
      @media (min-width: 991px) and (max-width: 1240px) {
        .gallery__slide:after {
          height: 201px; } }
      @media (max-width: 991px) {
        .gallery__slide:after {
          height: 242px; } }
      @media (max-width: 575px) {
        .gallery__slide:after {
          height: 201px; } }
      @media (max-width: 450px) {
        .gallery__slide:after {
          height: 166px; } }
    .gallery__slide.slick-current:before, .gallery__slide.prevSlide:before, .gallery__slide.nextSlide:before {
      opacity: 0;
      z-index: -1; }
    @media (max-width: 991px) {
      .gallery__slide.prevSlide:before, .gallery__slide.nextSlide:before {
        opacity: 1;
        z-index: 1; } }
    .gallery__slide .form__item.button .button {
      width: 100%;
      margin-left: -15px; }
  .gallery__img {
    width: 100%;
    height: 100%;
    height: 242px;
    position: relative;
    z-index: 2;
    left: -15px;
    bottom: -15px; }
    .gallery__img picture img {
      position: absolute;
      min-width: 100%;
      min-height: 100%; }
    @media (min-width: 991px) and (max-width: 1240px) {
      .gallery__img {
        height: 201px; } }
    @media (max-width: 991px) {
      .gallery__img {
        height: 242px; } }
    @media (max-width: 575px) {
      .gallery__img {
        height: 201px; } }
    @media (max-width: 450px) {
      .gallery__img {
        height: 166px; } }
  .gallery__price {
    z-index: 2;
    background-color: #f8f5ef;
    padding-top: 22px;
    width: 225px;
    margin-bottom: 25px;
    margin-left: -15px; }
    @media (max-width: 767px) {
      .gallery__price {
        margin-bottom: 15px; } }
    .gallery__price span {
      font-size: 14px;
      line-height: 16px;
      color: #656565;
      margin-bottom: 6px;
      display: block;
      position: relative; }
      .gallery__price span sup {
        position: absolute;
        top: -3px; }
    .gallery__price p {
      font-size: 28px;
      line-height: 33px;
      color: #4b1a08;
      font-weight: 600;
      margin-bottom: 10px; }

.header-menu {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 9px 0; }
  @media (max-width: 1124px) {
    .header-menu .container {
      width: 991px; } }
  @media (max-width: 1040px) {
    .header-menu .container {
      width: 95%;
      margin-left: 2.5%; } }
  .header-menu a {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
    font-weight: 600; }
    @media (max-width: 1124px) {
      .header-menu a {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500; } }
  .header-menu__email {
    margin-right: 25px; }
    @media (max-width: 1240px) {
      .header-menu__email {
        margin-right: 15px; } }
    @media (max-width: 1040px) {
      .header-menu__email {
        margin-right: 5px; } }
    .header-menu__email a {
      padding-left: 29px;
      position: relative; }
      .header-menu__email a:before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 14px;
        background-image: url(../images/icons/e-mail.svg);
        background-position: center;
        -webkit-background-size: contain;
        background-size: contain;
        top: 3px; }
  .header-menu__phone {
    margin-right: 46px;
    padding: 15px 25px 16px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    align-items: center;
    transition: 0.3s; }
    @media (max-width: 1240px) {
      .header-menu__phone {
        margin-right: 15px;
        padding-left: 20px;
        padding-right: 20px; } }
    @media (max-width: 1140px) {
      .header-menu__phone {
        margin-right: 10px;
        padding-left: 15px;
        padding-right: 15px; } }
    .header-menu__phone .header-menu__dropdown-select {
      position: relative;
      padding-left: 27px; }
  .header-menu__dropdown {
    display: flex;
    position: relative; }
    .header-menu__dropdown.active .header-menu__phone, .header-menu__dropdown:hover .header-menu__phone {
      border-radius: 35px 35px 0 0;
      border-color: #89a549;
      background-color: #89a549; }
    .header-menu__dropdown.active .header-menu__arrow-down, .header-menu__dropdown:hover .header-menu__arrow-down {
      transform: rotate(-180deg);
      margin-top: 0; }
    .header-menu__dropdown.active .header-menu__dropdown-list, .header-menu__dropdown:hover .header-menu__dropdown-list {
      display: block; }
    .header-menu__dropdown.active .header-menu__lang, .header-menu__dropdown:hover .header-menu__lang {
      background-color: #89a549; }
    @media (max-width: 991px) {
      .header-menu__dropdown {
        margin-left: auto; } }
  .header-menu__dropdown-list {
    position: absolute;
    padding: 7px 25px 18px;
    background-color: #89a549;
    left: 0;
    z-index: 1;
    width: calc(100% - 46px);
    top: 51px;
    display: none; }
    @media (max-width: 1240px) {
      .header-menu__dropdown-list {
        width: calc(100% - 15px); } }
    @media (max-width: 991px) {
      .header-menu__dropdown-list {
        width: calc(100% - 10px); } }
    .header-menu__dropdown-list .form__item .button {
      margin: 0 auto;
      border-radius: 30px !important;
      overflow: hidden; }
  .header-menu .lang-dropdown .header-menu__dropdown-list {
    padding: 10px 14px 7px;
    width: 100%;
    min-width: 110px;
    top: 24px; }
    .header-menu .lang-dropdown .header-menu__dropdown-list .lang {
      width: 100%;
      background-position: center right;
      border-radius: 0;
      margin-left: 0;
      line-height: 24px;
      color: #fff;
      font-weight: bold;
      font-weight: 600;
      margin-bottom: 10px; }
  .header-menu__dropdown-item {
    margin-bottom: 22px; }
    .header-menu__dropdown-item:last-child {
      margin-bottom: 0; }
  .header-menu__arrow-down {
    margin-left: 7px;
    width: 17.89px;
    height: 10px;
    background-image: url(../images/icons/arrow-bottom.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 4px; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .header-menu__arrow-down {
        -webkit-background-size: 88%;
        background-size: 88%; } }
    .header-menu__arrow-down:hover {
      opacity: 0.7; }
  .header-menu__lang {
    padding: 14px 14px 0;
    margin-top: -14px;
    margin-right: -14px;
    display: flex;
    color: #fff;
    font-weight: bold;
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    min-width: 110px; }
    .header-menu__lang .header-menu__dropdown-select {
      margin-right: 2px; }
    @media (min-width: 991px) and (max-width: 1040px) {
      .header-menu__lang {
        padding: 10px 10px 0;
        margin-right: -10px; } }
    @media (max-width: 991px) {
      .header-menu__lang {
        margin-left: auto; } }
  .header-menu .lang {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 24px;
    background-size: 24px;
    margin-left: 10px;
    cursor: pointer; }
    .header-menu .lang.ru {
      background-image: url("../images/icons/ru.svg"); }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .header-menu .lang.ru {
          background-image: url("../images/icons/ru.png"); } }
    .header-menu .lang.en {
      background-image: url("../images/icons/en.svg"); }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .header-menu .lang.en {
          background-image: url("../images/icons/en.png"); } }
    .header-menu .lang.sp {
      background-image: url("../images/icons/sp.svg"); }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .header-menu .lang.sp {
          background-image: url("../images/icons/sp.png"); } }
    .header-menu .lang.fr {
      background-image: url("../images/icons/fr.svg"); }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .header-menu .lang.fr {
          background-image: url("../images/icons/fr.png"); } }
    .header-menu .lang.de {
      background-image: url("../images/icons/de.svg"); }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .header-menu .lang.de {
          background-image: url("../images/icons/de.png"); } }
  .header-menu .container {
    align-items: center; }
  @media (max-width: 991px) {
    .header-menu .main-menu {
      display: none; } }

.main-menu {
  margin-right: auto; }
  .main-menu li {
    display: inline-block;
    margin-right: 15px;
    position: relative; }
    @media (max-width: 1240px) {
      .main-menu li {
        margin-right: 10px; } }
    @media (max-width: 991px) {
      .main-menu li {
        margin-right: 10px; } }
    @media (max-width: 991px) {
      .main-menu li {
        margin-right: 0; } }
    .main-menu li a {
      color: #fff;
      font-size: 16px;
      line-height: 20px; }
  .main-menu .mm-listview {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-top: 30px !important;
    position: relative; }
    .main-menu .mm-listview:before {
      content: "";
      background-color: #fff;
      opacity: 0.8;
      position: absolute;
      height: 1px;
      top: -10px;
      left: 0;
      right: 0; }
    .main-menu .mm-listview li {
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 17px;
      font-weight: 600;
      padding: 0; }
      .main-menu .mm-listview li a {
        padding: 0; }
      .main-menu .mm-listview li:after {
        content: none; }

.phone-link {
  position: relative;
  padding-left: 29px; }
  .phone-link:before {
    content: "";
    position: absolute;
    left: 0px;
    width: 18px;
    height: 18px;
    background-image: url("../images/icons/phone-white.svg");
    background-position: center;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0px; }

.hamburger {
  display: none;
  width: 35px;
  height: 24px;
  background-image: url(../images/icons/menu.svg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
  cursor: pointer; }

.mm-panels {
  background-color: #3d3837; }
  .mm-panels > .mm-panel.mm-hasnavbar {
    padding-top: 0; }

.mm-menu .mm-title {
  display: none !important; }

.mm-menu .mm-navbar {
  padding: 0;
  height: auto;
  border-bottom: 0;
  text-align: left;
  position: relative; }
  .mm-menu .mm-navbar a {
    color: #fff;
    font-size: 16px;
    font-weight: 600; }
  .mm-menu .mm-navbar .close {
    width: 29px;
    height: 29px;
    background-image: url(../images/icons/close.svg);
    -webkit-background-size: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-bottom: 27px; }
  .mm-menu .mm-navbar .header-menu__email {
    margin-bottom: 18px; }
  .mm-menu .mm-navbar .header-menu__phone {
    border: none;
    padding: 0; }
    .mm-menu .mm-navbar .header-menu__phone:hover {
      background-color: transparent;
      border: none; }
  .mm-menu .mm-navbar .header-menu__dropdown {
    flex-direction: column; }
    .mm-menu .mm-navbar .header-menu__dropdown .header-menu__arrow-down {
      display: none; }
    .mm-menu .mm-navbar .header-menu__dropdown:hover .header-menu__phone {
      border: none;
      padding: 0;
      background-color: transparent; }
  .mm-menu .mm-navbar .header-menu__dropdown-list {
    position: relative;
    display: block;
    background-color: transparent;
    top: 0;
    padding: 0;
    margin-top: 17px; }
  .mm-menu .mm-navbar .phone-link {
    padding-left: 27px; }
  .mm-menu .mm-navbar .form__item.button .button {
    margin: 0; }

.main-contacts {
  display: block;
  background-image: url(../images/main-contacts-bg.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  -webkit-background-size: cover;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (max-width: 1124px) {
    .main-contacts {
      padding-bottom: 80px; } }
  @media (max-width: 767px) {
    .main-contacts {
      padding-top: 70px; } }
  .main-contacts__block {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 70px 70px 70px 100px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15); }
    @media (max-width: 767px) {
      .main-contacts__block {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 50px;
        padding-bottom: 40px; } }
    @media (max-width: 575px) {
      .main-contacts__block {
        padding-right: 25px;
        padding-left: 25px; } }
  .main-contacts__caption {
    margin-bottom: 28px;
    width: 100%;
    font-weight: 500;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    position: relative;
    z-index: 1; }
  .main-contacts__title {
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    color: #7a9341;
    margin-bottom: 7px;
    font-weight: 600;
    position: relative;
    z-index: 1; }
  .main-contacts__item {
    display: flex;
    flex-direction: column; }
    @media (max-width: 1124px) {
      .main-contacts__item {
        width: 48%;
        margin-bottom: 20px; } }
    @media (max-width: 500px) {
      .main-contacts__item {
        width: 100%; } }
    .main-contacts__item a, .main-contacts__item p {
      color: #fff;
      color: #333;
      font-size: 14px;
      line-height: 24px;
      position: relative; }
      .main-contacts__item a:before, .main-contacts__item p:before {
        content: "";
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 100%;
        background-size: 100%;
        position: absolute; }
      .main-contacts__item a.phone, .main-contacts__item p.phone {
        padding-left: 17px; }
        .main-contacts__item a.phone:before, .main-contacts__item p.phone:before {
          width: 10px;
          height: 10px;
          margin-top: 6px;
          background-image: url(../images/icons/phone.svg); }
      .main-contacts__item a.email, .main-contacts__item p.email {
        padding-left: 29px; }
        .main-contacts__item a.email:before, .main-contacts__item p.email:before {
          width: 14px;
          height: 10px;
          margin-top: 7px;
          background-image: url(../images/icons/email.svg); }
      .main-contacts__item a.address, .main-contacts__item p.address {
        max-width: 185px;
        padding-left: 26px; }
        .main-contacts__item a.address:before, .main-contacts__item p.address:before {
          width: 11px;
          height: 15px;
          margin-top: 2px;
          background-image: url(../images/icons/placemark.svg); }

.main-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 120px; }
  @media (max-width: 991px) {
    .main-slider {
      margin-bottom: 90px; } }
  @media (max-width: 767px) {
    .main-slider {
      margin-bottom: 70px; } }
  .main-slider__logo {
    position: absolute;
    top: 100px;
    width: 280px;
    z-index: 1; }
    @media (max-width: 900px) {
      .main-slider__logo {
        width: 167px;
        top: 67px; } }
  @media screen and (max-width: 900px) and (-ms-high-contrast: active), (max-width: 900px) and (-ms-high-contrast: none) {
    .main-slider__logo {
      left: calc(50% - 133.5px); } }
    @media (min-width: 900px) and (max-height: 665px) {
      .main-slider__logo {
        width: 200px;
        top: 60px; } }
  @media screen and (min-width: 900px) and (max-height: 665px) and (-ms-high-contrast: active), (min-width: 900px) and (max-height: 665px) and (-ms-high-contrast: none) {
    .main-slider__logo {
      left: calc(50% - 100px); } }
    @media (max-width: 900px) and (orientation: landscape) {
      .main-slider__logo {
        top: 20px; } }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .main-slider__logo {
        left: calc(50% - 140px); } }
  .main-slider__slides {
    width: 100%;
    margin-bottom: 0 !important; }
  .main-slider__img {
    z-index: 1;
    position: absolute;
    width: 100%;
    min-height: 500px;
    height: 100vh;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    top: 0; }
    @media (max-width: 991px) and (orientation: landscape) {
      .main-slider__img {
        min-height: 320px; } }
  .main-slider__slide {
    width: 100%;
    height: 100vh;
    min-height: 500px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden; }
    @media (max-width: 991px) and (orientation: landscape) {
      .main-slider__slide {
        min-height: 320px; } }
    .main-slider__slide::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #090909 0%, rgba(9, 9, 9, 0.317708) 28.84%, rgba(9, 9, 9, 0) 221.5%);
      opacity: 0.2; }
  .main-slider__text {
    position: absolute;
    z-index: 3;
    width: 1146px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .main-slider__text {
        left: calc(50% - 573px); } }
    @media (max-width: 1240px) {
      .main-slider__text {
        width: 991px; } }
  @media screen and (max-width: 1240px) and (-ms-high-contrast: active), (max-width: 1240px) and (-ms-high-contrast: none) {
    .main-slider__text {
      left: calc(50% - 495.5px); } }
    @media (max-width: 1124px) {
      .main-slider__text {
        width: 900px; } }
  @media screen and (max-width: 1124px) and (-ms-high-contrast: active), (max-width: 1124px) and (-ms-high-contrast: none) {
    .main-slider__text {
      left: calc(50% - 450px); } }
    @media (max-width: 767px) {
      .main-slider__text {
        width: auto; } }
  .main-slider__title {
    font-size: 60px;
    line-height: 73px;
    color: #fff;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 70%;
    margin-bottom: 20px;
    font-weight: 600; }
    @media (max-width: 1124px) {
      .main-slider__title {
        font-size: 45px;
        line-height: 67px;
        width: 70%; } }
    @media (max-width: 767px) {
      .main-slider__title {
        font-size: 35px;
        line-height: 47px; } }
    @media (max-width: 575px) {
      .main-slider__title {
        font-size: 25px;
        line-height: 37px;
        width: 73%; } }
    @media (max-width: 991px) and (orientation: landscape) {
      .main-slider__title {
        font-size: 35px;
        line-height: 47px; } }
    @media (max-height: 375px) and (orientation: landscape) {
      .main-slider__title {
        font-size: 28px;
        line-height: 38px; } }
  .main-slider__desc {
    font-size: 32px;
    line-height: 38px;
    color: #fff;
    text-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    width: 40%; }
    @media (max-width: 1124px) {
      .main-slider__desc {
        width: 65%;
        font-size: 28px;
        line-height: 34px; } }
    @media (max-width: 767px) {
      .main-slider__desc {
        font-size: 22px;
        line-height: 28px; } }
    @media (max-width: 575px) {
      .main-slider__desc {
        font-size: 18px;
        line-height: 24px;
        width: 73%; } }
    @media (max-width: 991px) and (orientation: landscape) {
      .main-slider__desc {
        font-size: 22px;
        line-height: 28px; } }
    @media (max-height: 375px) and (orientation: landscape) {
      .main-slider__desc {
        font-size: 18px;
        line-height: 24px; } }

.our-partners {
  display: block;
  background-image: url(../images/our-partners-bg.png);
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 126px 0 200px; }
  @media (max-width: 991px) {
    .our-partners {
      padding-top: 106px;
      padding-bottom: 150px; } }
  @media (max-width: 767px) {
    .our-partners {
      padding-top: 70px;
      padding-bottom: 80px; } }
  .our-partners .page-title {
    color: #fff; }
  .our-partners__slider {
    width: 100%;
    position: relative; }
    .our-partners__slider .slider-arrow.prev {
      left: 30px; }
      @media (max-width: 1124px) {
        .our-partners__slider .slider-arrow.prev {
          left: -15px; } }
    .our-partners__slider .slider-arrow.next {
      right: 30px; }
      @media (max-width: 1124px) {
        .our-partners__slider .slider-arrow.next {
          right: -15px; } }
    .our-partners__slider .slick-dots {
      bottom: -50px; }
  .our-partners__slides {
    width: 100%; }
    .our-partners__slides .slick-list {
      padding-left: 30px;
      margin-left: -29px;
      margin-right: -29px; }
  .our-partners__slide {
    background: #3E3E3E;
    background: #fff;
    padding: 55px 25px 55px 35px;
    margin-right: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: inherit; }
    @media (max-width: 767px) {
      .our-partners__slide {
        padding-top: 40px;
        padding-bottom: 40px; } }
  .our-partners__img {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767px) {
      .our-partners__img {
        height: 45px; } }
    .our-partners__img img {
      max-height: 100%; }
  .our-partners__about {
    color: #fff;
    color: #000;
    text-transform: uppercase;
    width: calc(100% - 85px);
    margin-left: 0px; }
    @media (max-width: 991px) {
      .our-partners__about {
        width: calc(100% - 80px);
        margin-left: 20px; } }
    @media (max-width: 767px) {
      .our-partners__about {
        width: calc(100% - 60px);
        margin-left: 15px; } }
    .our-partners__about p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600; }
      @media (max-width: 991px) {
        .our-partners__about p {
          font-size: 18px;
          line-height: 28px; } }
      @media (max-width: 767px) {
        .our-partners__about p {
          font-size: 16px;
          line-height: 26px; } }
    .our-partners__about span {
      line-height: 30px;
      font-size: 14px;
      display: block;
      font-weight: 600; }

.type-complex {
  display: block;
  padding: 137px 0 206px;
  background-image: url(../images/type-complex-bg.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom; }
  @media (max-width: 991px) {
    .type-complex {
      padding-top: 100px;
      padding-bottom: 166px; } }
  @media (max-width: 767px) {
    .type-complex {
      padding-top: 70px;
      padding-bottom: 96px; } }
  .type-complex__item {
    width: 362px;
    width: 31.58%;
    margin-right: 30px;
    height: 538px;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); }
    .type-complex__item.onScroll .type-complex__title {
      background-color: #aecd67; }
    @media (min-width: 991px) {
      .type-complex__item:nth-child(3n) {
        margin-right: 0; } }
    @media (max-width: 1240px) {
      .type-complex__item {
        margin-right: 26px; } }
    @media (max-width: 1124px) {
      .type-complex__item {
        width: 31%; } }
    @media (max-width: 991px) {
      .type-complex__item {
        margin-right: auto;
        margin-left: auto;
        width: calc(50% - 13px);
        margin-bottom: 30px; }
        .type-complex__item:first-child {
          margin-right: 26px; } }
    @media (max-width: 767px) {
      .type-complex__item {
        width: calc(50% - 7.5px); }
        .type-complex__item:first-child {
          margin-right: 15px; } }
    @media (max-width: 575px) {
      .type-complex__item {
        width: 100%; }
        .type-complex__item:first-child {
          margin-right: 0; } }
  .type-complex__title {
    background-color: #89a549;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: 0.3s; }
  .type-complex__list {
    height: calc(100% - 90px); }
  .type-complex__inner {
    padding: 30px;
    background-color: #fff; }
    .type-complex__inner:nth-child(2n) {
      background-color: #FAFAFA; }
    .type-complex__inner p {
      font-size: 14px;
      line-height: 24px;
      color: #333;
      margin-bottom: 0; }
    @media (max-width: 767px) {
      .type-complex__inner {
        padding: 20px; } }

* {
  font-family: "HelveticaNeueCyr"; }

@media (max-width: 990px) {
  body > div {
    overflow: hidden; } }

.slider-arrows {
  width: 1146px;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 1146px / 2);
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1240px) {
    .slider-arrows {
      width: 991px;
      left: calc(50% - 991px / 2); } }
  @media (max-width: 991px) {
    .slider-arrows {
      width: 90%;
      left: 5%; } }
  @media (max-width: 767px) {
    .slider-arrows {
      width: 94%;
      left: 3%; } }

.slider-arrow {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-image: url(../images/icons/left.svg);
  background-position: 45% 50%;
  background-repeat: no-repeat;
  -webkit-background-size: 10px;
  background-size: 10px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: calc(50% - 24px);
  transition: 0.3s; }
  @media (max-width: 767px) {
    .slider-arrow {
      width: 38px;
      height: 38px;
      -webkit-background-size: 8px;
      background-size: 8px;
      top: calc(50% - 19px); } }
  .slider-arrow:hover {
    background-color: #89a549;
    background-image: url(../images/icons/left-white.svg); }
  .slider-arrow.prev {
    left: 0; }
  .slider-arrow.next {
    right: 0;
    transform: rotate(180deg); }

.slick-dots {
  position: absolute;
  bottom: 50px;
  z-index: 2; }
  @media (max-width: 767px) and (orientation: landscape) {
    .slick-dots {
      bottom: 10px; } }
  .slick-dots li {
    width: 14px;
    height: 14px; }
    .slick-dots li button {
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 50%;
      padding: 0;
      margin: 0; }
      .slick-dots li button:before {
        content: none; }
    .slick-dots li.slick-active button {
      background-color: #89a549; }

.container {
  margin: 0 auto;
  width: 1146px;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 1240px) {
    .container {
      width: 991px; } }
  @media (max-width: 1124px) {
    .container {
      width: 900px; } }
  @media (max-width: 991px) {
    .container {
      width: 90%;
      margin-left: 5%; } }

img {
  max-width: 100%; }

picture img {
  max-width: 100%; }

.page-title {
  font-size: 48px;
  line-height: 57px;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  margin-bottom: 60px;
  font-weight: 600; }
  @media (max-width: 991px) {
    .page-title {
      font-size: 36px;
      line-height: 47px;
      margin-bottom: 45px; } }
  @media (max-width: 767px) {
    .page-title {
      font-size: 28px;
      line-height: 39px;
      margin-bottom: 35px; } }

a {
  text-decoration: none;
  transition: 0.3s; }
  a:hover {
    opacity: 0.7; }

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative; }
  .form__item {
    position: relative;
    width: 24.17%;
    margin-bottom: 50px; }
    @media (max-width: 575px) {
      .form__item {
        width: 100%; } }
    .form__item label {
      position: absolute;
      left: 0;
      bottom: 5px;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.3);
      z-index: 1; }
    .form__item input {
      width: 100%;
      background-color: transparent;
      outline: none;
      border: 0;
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
      position: relative;
      z-index: 2;
      padding-bottom: 5px; }
    .form__item.textarea {
      width: 100%; }
    .form__item--focus label {
      font-size: 14px;
      line-height: 17px;
      bottom: 32px; }
    .form__item.button {
      width: auto;
      margin: 0 auto; }
      .form__item.button .button {
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        border-radius: 4px;
        background-color: #aecd67;
        width: 153px;
        height: 40px;
        padding-bottom: 0;
        border-bottom: 0;
        font-weight: 500;
        transition: 0.3s;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center; }
        .form__item.button .button:hover {
          background-color: #89a549; }
        .form__item.button .button:active {
          background-color: #7a9341; }
  .form__success {
    width: 153px;
    height: 153px;
    background-image: url(../images/icons/success.svg);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    margin-left: -76.5px;
    margin-top: -76.5px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    opacity: 0; }
    .form__success.success {
      z-index: 2;
      animation-delay: 0.5s;
      animation: formSuccess 3.2s; }

@keyframes formSuccess {
  0% {
    opacity: 0;
    margin-top: -153px; }
  20% {
    opacity: 1;
    margin-top: -76.5px; }
  40% {
    opacity: 1;
    margin-top: -76.5px; }
  60% {
    opacity: 1;
    margin-top: -76.5px; }
  80% {
    opacity: 1;
    margin-top: -76.5px; }
  100% {
    opacity: 0;
    margin-top: 0px; } }

.mCSB_inside > .mCSB_container {
  margin-right: 0; }

.mCSB_scrollTools .mCSB_draggerContainer {
  margin-top: 10px;
  margin-bottom: 10px; }

.mCSB_scrollTools .mCSB_draggerRail {
  background: #E9F0D9;
  border-radius: 10px;
  width: 7px; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #7a9341 !important;
  border-radius: 10px;
  width: 7px; }

.d-block {
  display: block !important; }

@media (max-width: 991px) {
  .animateme.scrollme {
    opacity: 0; } }
