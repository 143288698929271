$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1146px;
$brown: #3d3837;
$dark-red: #4b1a08;
$dark-green: #7a9341;
$green: #89a549;
$light-green: #aecd67;
$light-gray: #f8f5ef;
$default-font: 'HelveticaNeueCyr';
