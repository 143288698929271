.dialogs {
	display: block;
}
.more-dialog{
	background-color: rgb(0,0,0);

	width: 700px;
	max-width: 100%;
	padding: 50px;
	display: none;
}
.fancybox-is-open .fancybox-bg{
	opacity: 0.2;
}
