.header-menu {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 9px 0;
  .container{
    @media (max-width: 1124px){
      width: 991px;
    }
    @media (max-width: 1040px){
      width: 95%;
      margin-left: 2.5%;
    }
  }
  a {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
    font-weight: 600;
    @media (max-width: 1124px) {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
  }

  &__email {
    margin-right: 25px;
    @media (max-width: 1240px) {
      margin-right: 15px;
    }
    @media (max-width: 1040px) {
      margin-right: 5px;
    }


    a {
      padding-left: 29px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 14px;
        background-image: url(../images/icons/e-mail.svg);
        background-position: center;
        -webkit-background-size: contain;
        background-size: contain;
        top: 3px;
      }
    }
  }

  &__phone {
    margin-right: 46px;
    padding: 15px 25px 16px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    @media (max-width: 1240px) {
      margin-right: 15px;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: 1140px) {
      margin-right: 10px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .header-menu__dropdown-select {
      position: relative;
      padding-left: 27px;
    }
  }



  &__dropdown {
    display: flex;
    position: relative;

    &.active,
    &:hover {
      .header-menu__phone {
        border-radius: 35px 35px 0 0;
        border-color: $green;
        background-color: $green;
      }

      .header-menu__arrow-down {
        transform: rotate(-180deg);
        margin-top: 0;
      }

      .header-menu__dropdown-list {
        display: block;
      }

      .header-menu__lang {
        background-color: $green;
      }
    }
    @media (max-width: 991px){
      margin-left: auto;
    }
  }

  &__dropdown-list {
    position: absolute;
    padding: 7px 25px 18px;
    background-color: $green;
    left: 0;
    z-index: 1;
    width: calc(100% - 46px);
    top: 51px;
    display: none;
    @media (max-width: 1240px) {
      width: calc(100% - 15px);
    }
    @media (max-width: 1124px){

    }
    @media (max-width: 991px) {
      width: calc(100% - 10px);
    }
    .form__item {
      .button {
        margin: 0 auto;
        border-radius: 30px !important;
        overflow: hidden;
      }
    }
  }

  .lang-dropdown {
    .header-menu__dropdown-list {
      padding: 10px 14px 7px;
      width: 100%;
      min-width: 110px;
      top: 24px;
      .lang {
        width: 100%;
        background-position: center right;
        border-radius: 0;
        margin-left: 0;
        line-height: 24px;
        color: #fff;
        font-weight: bold;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  &__dropdown-item {
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__arrow-down {
    margin-left: 7px;
    width: 17.89px;
    height: 10px;
    background-image: url(../images/icons/arrow-bottom.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 4px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      -webkit-background-size: 88%;
      background-size: 88%;
    }
    &:hover {
      opacity: 0.7;
    }

  }

  &__lang {
    padding: 14px 14px 0;
    margin-top: -14px;
    margin-right: -14px;
    display: flex;
    color: #fff;
    font-weight: bold;
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    min-width: 110px;
    .header-menu__dropdown-select {
      margin-right: 2px;
    }
    @media (min-width: 991px) and (max-width: 1040px){
      padding: 10px 10px 0;
      margin-right: -10px;
    }
    @media (max-width: 991px) {
      margin-left: auto;
    }
  }

  .lang {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 24px;
    background-size: 24px;
    margin-left: 10px;
    cursor: pointer;

    &.ru {
      background-image: url("../images/icons/ru.svg");
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url("../images/icons/ru.png");
      }
    }

    &.en {
      background-image: url("../images/icons/en.svg");
       @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url("../images/icons/en.png");
      }
    }

    &.sp {
      background-image: url("../images/icons/sp.svg");
       @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url("../images/icons/sp.png");
      }
    }

    &.fr {
      background-image: url("../images/icons/fr.svg");
       @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url("../images/icons/fr.png");
      }
    }
    &.de {

      background-image: url("../images/icons/de.svg");
       @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url("../images/icons/de.png");
      }
    }
  }

  .container {
    align-items: center;
  }
  .main-menu{
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.main-menu {
  margin-right: auto;


  li {
    display: inline-block;
    margin-right: 15px;
    position: relative;
    @media (max-width: 1240px) {
      margin-right: 10px;
    }

    @media (max-width: 991px) {
      margin-right: 10px;
    }
    @media (max-width: 991px){
      margin-right: 0;
    }

    a {
      color: #fff;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .mm-listview {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-top: 30px!important;
    position: relative;
    &:before{
      content: "";

      background-color: #fff;
      opacity: 0.8;
      position: absolute;
      height: 1px;
      top: -10px;
      left: 0;
      right: 0;
    }

    li {
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 17px;
      font-weight: 600;
      padding: 0;

      a {
        padding: 0;
      }

      &:after {
        content: none;
      }
    }
  }
}
.phone-link {
  position: relative;
  padding-left: 29px;

  &:before {
    content: "";
    position: absolute;
    left: 0px;
    width: 18px;
    height: 18px;
    background-image: url("../images/icons/phone-white.svg");
    background-position: center;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0px;
  }
}
.hamburger {
  display: none;
  width: 35px;
  height: 24px;
  background-image: url(../images/icons/menu.svg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
  cursor: pointer;
}

.mm-panels {
  background-color: $brown;
  & > .mm-panel.mm-hasnavbar{
    padding-top: 0;
  }
}

.mm-menu {
  .mm-title {
    display: none !important;
  }

  .mm-navbar {
    padding: 0;
    height: auto;
    border-bottom: 0;
    text-align: left;
    position: relative;

    a {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
    .close{
      width: 29px;
      height: 29px;
      background-image: url(../images/icons/close.svg);
      -webkit-background-size: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      margin-bottom: 27px;
    }
    .header-menu__email{
      margin-bottom: 18px;
    }
    .header-menu__phone {
      border: none;
      padding: 0;

      &:hover {
        background-color: transparent;
        border: none;
      }
    }

    .header-menu__dropdown {
      flex-direction: column;
      .header-menu__arrow-down{
        display: none;
      }
      &:hover {
        .header-menu__phone {
          border: none;
          padding: 0;
          background-color: transparent;
        }
      }
    }

    .header-menu__dropdown-list {
      position: relative;
      display: block;
      background-color: transparent;
      top: 0;
      padding: 0;
      margin-top: 17px;
    }
    .phone-link{
      padding-left: 27px;
    }
    .form__item.button .button{
      margin: 0;
    }
  }
}
