@import 'helpers/variables';
@import 'helpers/mixins';
@import 'helpers/fonts';
@import 'helpers/optimize';
@import "C:/in progress/wood/app/blocks/about-us/about-us.scss";
@import "C:/in progress/wood/app/blocks/advantages/advantages.scss";
@import "C:/in progress/wood/app/blocks/calculate/calculate.scss";
@import "C:/in progress/wood/app/blocks/certifications/certifications.scss";
@import "C:/in progress/wood/app/blocks/collaboration/collaboration.scss";
@import "C:/in progress/wood/app/blocks/dialogs/dialogs.scss";
@import "C:/in progress/wood/app/blocks/footer/footer.scss";
@import "C:/in progress/wood/app/blocks/frame-buildings/frame-buildings.scss";
@import "C:/in progress/wood/app/blocks/gallery/gallery.scss";
@import "C:/in progress/wood/app/blocks/header-menu/header-menu.scss";
@import "C:/in progress/wood/app/blocks/main-contacts/main-contacts.scss";
@import "C:/in progress/wood/app/blocks/main-slider/main-slider.scss";
@import "C:/in progress/wood/app/blocks/our-partners/our-partners.scss";
@import "C:/in progress/wood/app/blocks/type-complex/type-complex.scss";

* {
  font-family: $default-font;
}

body {
  @media (max-width: 990px) {
    & > div {
      overflow: hidden;
    }
  }
}

.slider-arrows {
  width: $xl;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 1146px / 2);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1240px) {
    width: 991px;
    left: calc(50% - 991px / 2);
  }
  @media (max-width: 991px) {
    width: 90%;
    left: 5%;
  }
  @media (max-width: 767px) {
    width: 94%;
    left: 3%;
  }
}

.slider-arrow {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-image: url(../images/icons/left.svg);
  background-position: 45% 50%;
  background-repeat: no-repeat;
  -webkit-background-size: 10px;
  background-size: 10px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: calc(50% - 24px);
  transition: 0.3s;
  @media (max-width: 767px) {
    width: 38px;
    height: 38px;
    -webkit-background-size: 8px;
    background-size: 8px;
    top: calc(50% - 19px);
  }

  &:hover {
    background-color: $green;
    background-image: url(../images/icons/left-white.svg);
  }

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
    transform: rotate(180deg);
  }
}

.slick-dots {
  position: absolute;
  bottom: 50px;
  z-index: 2;
  @media (max-width: 767px) and (orientation: landscape) {
    bottom: 10px;

  }

  li {
    width: 14px;
    height: 14px;

    button {
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 50%;
      padding: 0;
      margin: 0;

      &:before {
        content: none;
      }
    }

    &.slick-active {
      button {
        background-color: $green;
      }
    }
  }
}

.container {
  margin: 0 auto;
  width: $xl;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1240px) {
    width: 991px;
  }
  @media (max-width: 1124px) {
    width: 900px;
  }
  @media (max-width: 991px) {
    width: 90%;
    margin-left: 5%;
  }
}

img {
  max-width: 100%;
}

picture {
  img {
    max-width: 100%;
  }
}

.page-title {
  font-size: 48px;
  line-height: 57px;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  margin-bottom: 60px;
  font-weight: 600;
  @media (max-width: 991px) {
    font-size: 36px;
    line-height: 47px;
    margin-bottom: 45px;
  }
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 39px;
    margin-bottom: 35px;
  }
}

a {
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  &__item {
    position: relative;
    width: 24.17%;
    margin-bottom: 50px;
    @media (max-width: 575px) {
      width: 100%;
    }

    label {
      position: absolute;
      left: 0;
      bottom: 5px;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.3);
      z-index: 1;
    }

    input {
      width: 100%;
      background-color: transparent;
      outline: none;
      border: 0;
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
      position: relative;
      z-index: 2;
      padding-bottom: 5px;
    }

    &.textarea {
      width: 100%;
    }

    &--focus {
      label {
        font-size: 14px;
        line-height: 17px;
        bottom: 32px;
      }
    }

    &.button {
      width: auto;
      margin: 0 auto;

      .button {
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        border-radius: 4px;
        background-color: $light-green;
        width: 153px;
        height: 40px;
        padding-bottom: 0;
        border-bottom: 0;
        font-weight: 500;
        transition: 0.3s;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $green;
        }

        &:active {
          background-color: $dark-green;
        }
      }
    }
  }
  &__success{
    width: 153px;
    height: 153px;
    background-image: url(../images/icons/success.svg);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    margin-left: -76.5px;
    margin-top: -76.5px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    opacity: 0;
    &.success{
      z-index: 2;
      animation-delay: 0.5s;
      animation: formSuccess 3.2s;
    }
  }
}
@keyframes formSuccess {
  0%{
    opacity: 0;
    margin-top: -153px;
  }
  20%{
    opacity: 1;
    margin-top: -76.5px;
  }
  40%{
    opacity: 1;
    margin-top: -76.5px;
  }
  60%{
    opacity: 1;
    margin-top: -76.5px;
  }
  80%{
    opacity: 1;
    margin-top: -76.5px;
  }
  100%{
    opacity: 0;
    margin-top: 0px;
  }
}
.mCSB_inside > .mCSB_container {
  margin-right: 0;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mCSB_scrollTools .mCSB_draggerRail {
  background: #E9F0D9;
  border-radius: 10px;
  width: 7px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: $dark-green !important;
  border-radius: 10px;
  width: 7px;
}
.d-block{
  display: block!important;
}
@media (max-width: 991px){
  .animateme.scrollme{
    opacity: 0;
  }
}
