.calculate {
	display: block;
	background-image: url(../images/calc-bg.png);
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding-top: 127px;
	padding-bottom: 140px;
	@media (max-width: 991px){
		padding-top: 100px;
		padding-bottom: 120px;
	}
	@media (max-width: 767px){
		padding-top: 70px;
		padding-bottom: 90px;
	}
	&__title{
		font-size: 36px;
		line-height: 43px;
		color: #fff;
		width: 450px;
		max-width: 100%;
		text-align: center;
		margin: 0 auto 60px;
		font-weight: 600;
	}
	.main-contacts__block{
		margin-bottom: 94px;
		padding-top: 50px;
		padding-bottom: 50px;
		background: rgba(255, 255, 255, 0.1);
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
		margin-top: 0;
		a,p{
			color: #fff;
		}

	}
	&__form{
		width: 100%;

	}
}
