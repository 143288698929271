.frame-buildings {
  display: block;
  background-image: url(../images/icons/buildings.svg);
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 130px 0 110px;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px){
    padding: 100px 0 90px;
  }
  @media (max-width: 767px){
    padding: 70px 0 60px;
  }
  .page-title {
    width: 100%;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__icon {
    height: 129px;


    img {
      height: 100%;
      @media (max-width: 767px) {
        margin: 0 auto;
        display: block;
      }
    }

  }

  &__item {
    width: 362px;
    //height: 420px;
    width: 31.58%;
    margin-right: 30px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    margin-bottom: 30px;
    padding: 28px;
    @media (min-width: 991px) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @media (max-width: 1240px) {
      //width: 310px;
      width: 31%;
    }
    @media (max-width: 991px) {
      width: calc(50% - 15px);
      margin-right: 30px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media (max-width: 767px) {
      width: calc(50% - 10px);
      padding: 20px;
      margin-right: 20px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
    }

  }

  &__title {
    font-size: 14px;
    line-height: 22px;
    color: $dark-green;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 600;
    @media (max-width: 575px){
      text-align: center;
    }
  }

  &__desc {
    font-size: 14px;
    line-height: 24px;
    color: #333;
  }
}
